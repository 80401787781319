import React from "react";
import { connect } from "react-redux";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactBody from "react-body";

const StyleguidePage = ({ language }) => {
  return (
    <Layout id="styleguide">
      <SEO title="Styleguide" />
      <section>
        <iframe id="designgapp" title="Styleguide" width="100%" height="768" frameBorder="0" src="https://www.designgapp.com/s/7iT82gxnr" onLoad={() => window.addEventListener('message', (e) => document.getElementById('designgapp').height = JSON.parse(e.data).height+'px')} allowFullScreen />
      </section>
      <ReactBody className="styleguide" />
    </Layout>
  );
}

const mapStateToProps = ({ language }) => {
  return { language }
};

export default connect(mapStateToProps)(StyleguidePage);